:root {
	--_overlay-color: rgba(0, 0, 0, 0.2);
}

.site-header {
	--_max-width: var(--wrapper-width, 1380px);
	--_spacing-xl: var(--wp--preset--spacing--xl, 3rem);
	--_spacing-xs: var(--wp--preset--spacing--xs, 1.5rem);
	--_spacing-s: var(--wp--preset--spacing--s, 1rem);
	--_spacing-m: var(--wp--preset--spacing--m, 1.25rem);
	--_spacing-l: var(--wp--preset--spacing--l, 1.25rem);
	--_color-contrast: var(--wp--preset--color--contrast, #172b02);
	--_caret: var(--icon-url--angle-down);
	--_caret-rotation: 0;
	// https://www.marcbacon.com/tools/clamp-calculator/
	// Up to 40px
	--_nav-gap: clamp(0.625rem, -10.833rem + 16.667vw, 1rem);
	// Up to 40px
	--header-button-padding: clamp(1.25rem, -6.389rem + 11.111vw, 1.5rem);
	--_line-height: 0.7;
	--_flyout-offset: 6px;
	--_transition-duration: 300ms;
	--_dim-item-opacity: 0.3;
	--_menu-open-url: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='21' height='14' fill='none'%3e%3cpath stroke='%23172B02' stroke-width='2' d='M0 1h21M0 7h21M0 13h21'/%3e%3c/svg%3e");
	--_menu-close-url: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='13' height='14' fill='none'%3e%3cpath fill='%23172B02' d='M1.857.635 0 2.492l3.936 3.936a1 1 0 0 1 0 1.414L0 11.778l1.857 1.857 3.936-3.936a1 1 0 0 1 1.414 0l3.936 3.936L13 11.778 9.064 7.842a1 1 0 0 1 0-1.414L13 2.492 11.143.635 7.207 4.57a1 1 0 0 1-1.414 0L1.857.635Z'/%3e%3c/svg%3e");
	--font-size--button: 14px;

	position: sticky;
	top: 0;
	z-index: 100;
	background: var(--wp--preset--color--base);
	font-size: 15px;
}

.site-header__container {
	margin: 0 auto;
	max-width: var(--_max-width);
	padding: 0 var(--_spacing-m);
}

.site-header__row {
	display: flex;
	padding: var(--_spacing-s) 0;
	align-items: center;
	justify-content: space-between;
}

.site-header__menu-toggle {
	--_icon: var(--_menu-open-url);

	width: 1.5rem;
	height: 1.5rem;
	background-color: transparent;
	border: none;
	appearance: none;
	background-image: var(--_icon);
	background-repeat: no-repeat;
	background-position: center;
	transition: background-image 0.3s ease-in-out;
	cursor: pointer;

	@media (min-width: 1100px) {
		display: none;
	}
}

.site-header__menu-toggle.is-active {
	--_icon: var(--_menu-close-url);
}

.main-nav__list a {
	color: var(--_color-contrast);
}

.main-nav__list > .menu-item {
	white-space: nowrap;
}

.menu-item__heading button {
	appearance: none;
	cursor: pointer;
	border: none;
	display: inline-block;
	margin-left: 7px;
	width: 11px;
	height: 10px;
	background-color: transparent;
	background-image: var(--_caret);
	background-position: 50% 60%;
	background-repeat: no-repeat;
	transform: rotate(var(--_caret-rotation, 0));
	transition: transform ease-in-out var(--_transition-duration, 300ms);
}

.site-header__nav .ht-button {
	line-height: var(--_line-height);
}

.site-header__nav > .ht-button:first-child {
	margin-left: auto;
}

.site-header__nav.is-open {
	--_display: block;
	--_translate: 0;
}

.menu-item-has-children.is-open {
	--_caret-rotation: 180deg;
}

.site-header__contact-us {

}

.site-header__contact-us .site-header__contact-us-trustpilot,
.site-header__contact-us .site-header__contact-us-phone {
	display: none;
}

.site-header__contact-us .site-header__contact-us-button {
	display: block;
}

@media (min-width: 1100px) {

	.site-header__contact-us {
		margin-left: auto;
		display: grid;
		grid-template-columns: auto auto auto;
		gap: 20px;
		align-items: center;
	}

	.site-header__contact-us .site-header__contact-us-trustpilot {
		display: block;
	}

	.site-header__contact-us .site-header__contact-us-phone,
	.site-header__contact-us .site-header__contact-us-button {
		display: none;
	}

	.site-header__contact-us.is-open .site-header__contact-us-phone {
		display: block;
		// font-size: 20px;
	}

	.site-header__contact-us.is-open .site-header__contact-us-button {
		display: none;
	}
}

@media (max-width: 1099px) {
	.site-header__nav {
		--_display: block;
		--_translate: -110%;
		// TODO: make a proper calculation
		--_top: calc(70px + var(--gap));
		--_max-height: calc(100vh - var(--_top) - var(--gap));

		display: var(--_display);
		position: fixed;
		left: var(--gap);
		right: var(--gap);
		top: var(--_top);
		max-height: var(--_max-height);
		overflow: auto;
		background: var(--wp--preset--color--base);
		border-radius: var(--ht-radius--s);
		padding: var(--_spacing-m) 0;
		transform: translateX(var(--_translate));
		transition: transform 300ms ease-in-out;

		.ht-button {
			margin-left: var(--_spacing-m);
		}

		.ht-button + .ht-button {
			margin-left: var(--_spacing-xs);
		}

		.menu-item {
			margin-bottom: var(--_spacing-l);
		}

		.menu-item ul {
			display: none;
			background: var(--wp--preset--color--primary);
			margin-top: var(--_spacing-m);
			padding: 40px 0;
		}

		.menu-item.is-open ul {
			display: block;
		}

		.menu-item li {
			margin-bottom: var(--_spacing-xl);
		}

		.menu-item li:last-child {
			margin-bottom: 0;
		}

		.main-nav__list a {
			// extend the active area, as long as it's not a block or inline-block
			// this won't affect neighbourhood spacing
			display: inline-block;
			padding-left: var(--_spacing-m);
			font-size: var(--font-size--button);
			line-height: 0.7;
			font-weight: 500;
			letter-spacing: 0.28px;
		}


	}

}


@media (min-width: 1100px) {
	.site-header__nav {
		display: flex;
		align-items: center;
		width: 100%;
	}

	.site-header__nav .ht-button {
		padding-left: var(--header-button-padding);
		padding-right: var(--header-button-padding);
	}

	.site-header__nav .main-nav__list {
		--_gap: var(--_nav-gap);

		display: flex;
		list-style: none;
		margin: 0 auto;
		// font-weight: 500;
		line-height: var(--_line-height);

		// Safari/Catalina does not support gap on flexbox layouts.
		> li {
			position: relative;
			margin-left: var(--_gap);
		}

		> li:first-child {
			margin-left: 0;
		}

		// flyouts.
		ul {
			display: none;
			opacity: 0;
			position: absolute;
			left: calc(var(--_spacing-s) * -1);
			top: calc(1em * var(--_line-height) + var(--_spacing-xs) + var(--_spacing-s) + var(--_flyout-offset));
			min-width: var(--_min-width, 300px);
			border-radius: var(--ht-radius--s);
			padding: var(--_spacing-m) var(--_spacing-s);
			background: var(--wp--preset--color--base);
			transition: opacity ease-in-out 10s;

			li {
				margin: var(--_spacing-s) 0;
			}

			li:first-child {
				margin-top: 0;
			}

			li:last-child {
				margin-bottom: 0;
			}

			a {
				--_border-color: transparent;
				--_border-color: var(--wp--preset--color--primary);

				display: inline-block;
				position: relative;
				white-space: nowrap;
				line-height: calc(var(--_line-height) * 3);
				padding: 0;
				padding-left: var(--_spacing-s);
			}

			a:hover,
			a:focus {
				--_border-opacity: 1;

			}

			a:before {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				width: 4px;
				border-radius: 2px;
				background: var(--_border-color);
				opacity: var(--_border-opacity, 0);
				transition: opacity ease-in-out var(--_transition-duration, 300ms);
			}
		}

		// conditions to show the flyout.
		.menu-item-has-children.is-open {
			> ul {
				display: block;
				opacity: 1;
				animation: fadeInFromNone var(--_transition-duration, 300ms) ease-out;
			}
		}

	}

	// dim non-active menu items.
	body.has-menu-overlay .site-header__nav > ul > .menu-item:not(.is-open) {
		opacity: var(--_dim-item-opacity, 0.3);
	}

}

// overlay
body::after {
	content: "";
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;
	background: var(--_overlay-color);
}

// we're showing the mobile menu or a flyout.
body.has-menu-overlay {
	&::after {
		display: block;
	}
}

@keyframes fadeInFromNone {
	0% {
		display: none;
		opacity: 0;
	}

	1% {
		display: block;
	}
	100% {
		opacity: 1
	}
}
